@import 'libs/design-system/src/styles.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: var(--font-default);
}
